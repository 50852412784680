export function getSocial(component) {
  const { instagram, facebook, youtube, twitter, linkedin } = component;
  const social = [];

  if (instagram) {
    social.push({
      text: "Instagram",
      link: instagram,
      icon: "fab fa-instagram",
      size: 18,
    });
  }

  if (facebook) {
    social.push({
      text: "Facebook",
      link: facebook,
      icon: "fab fa-facebook-f",
      size: 20,
    });
  }

  if (youtube) {
    social.push({
      text: "Youtube",
      link: youtube,
      icon: "fab fa-youtube",
      size: 18,
    });
  }

  if (twitter) {
    social.push({
      text: "Twitter",
      link: twitter,
      icon: "fab fa-twitter",
      size: 18,
    });
  }

  if (linkedin) {
    social.push({
      text: "Linkedin",
      link: linkedin,
      icon: "fab fa-linkedin",
      size: 18,
    });
  }

  return social;
}
