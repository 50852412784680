<template>
  <v-sheet class="d-flex flex-column justify-space-between" min-height="100vh">
    <Header />

    <div class="py-10 py-md-13 py-lg-16 px-3">
      <v-sheet class="mx-auto video" color="black" max-width="1200px">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/K9iIz4X8aoY?si=jkqaFYSOKiufr36A"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen
        ></iframe>
      </v-sheet>
    </div>

    <Footer />
  </v-sheet>
</template>

<script>
import Header from "./Header.vue";
import Footer from "./Footer.vue";

export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style lang="scss" scoped>
.item-img {
  pointer-events: none;
}

.video::v-deep {
  align-items: center;
  aspect-ratio: 16 / 9;
  display: flex;
  justify-content: center;
  object-fit: contain;

  iframe {
    display: block;
    height: 100% !important;
    width: 100% !important;
  }
}
</style>
