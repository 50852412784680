<template>
  <div class="contact-section">
    <div class="d-flex justify-start">
      <TitleWidget>Informações de contato</TitleWidget>
    </div>

    <div class="d-flex flex-column mt-8 align-start">
      <v-row no-gutters justify="start" align="start">
        <v-col
          class="d-flex justify-start pa-0"
          :cols="item.xs"
          :sm="item.sm"
          v-for="(item, keyContact) in contacts"
          :key="keyContact"
        >
          <a
            v-if="!Array.isArray(item)"
            target="_blank"
            :href="item.link"
            class="
              white-space
              d-flex
              align-center
              text-none
              mb-3
              py-1
              text-decoration-none
              px-0
            "
            :class="
              item.type === `highlight`
                ? `text-md-subtitle-1 text-body-1 font-weight-bold highlight mr-5`
                : `text-body-2 font-weight-medium`
            "
            :style="
              item.type == 'highlight' ? 'color: #009fe4' : 'color: #2c2c2c'
            "
            depressed
          >
            <v-icon
              :color="item.type === `highlight` ? `#009fe4` : `#2c2c2c`"
              class="mr-2"
              :size="item.size"
            >
              {{ item.icon }}
            </v-icon>
            <div>
              <span
                v-if="typeof item.text === `object`"
                class="text-caption font-weight-bold"
              >
                {{ item.text.ddd }}
              </span>
              <span class="break-word">
                {{
                  typeof item.text === "object" ? item.text.tel : item.text
                }}</span
              >
            </div>
          </a>

          <v-col
            cols="6"
            v-else
            v-for="(
              { name, text, link, target, icon, size, type }, keyContactItem
            ) in item"
            :key="keyContactItem"
            class="d-flex align-start justify-start pa-0"
          >
            <a
              class="
                white-space
                rounded-0
                d-flex
                align-center
                mb-3
                px-0
                py-1
                text-decoration-none
              "
              :class="
                type
                  ? `text-sm-subtitle-1 text-h6 primary--text highlight text--lighten-2 font-weight-bold mr-5`
                  : `text-caption`
              "
              depressed
              height="auto"
              min-height="36px"
              :href="link"
              :target="target"
              :title="name"
              color="transparent"
            >
              <v-icon
                :color="type ? `primary lighten-2` : ``"
                v-if="icon"
                :size="size"
                class="mr-2"
              >
                {{ icon }}
              </v-icon>

              <span v-html="text"></span>
            </a>
          </v-col>
        </v-col>
      </v-row>

      <div>
        <div class="font-weight-bold text-subtitle-1 mb-3 mt-5">
          Siga-nos nas redes sociais
        </div>
        <v-btn
          v-for="({ link, size, icon }, i) in social"
          :key="i"
          height="46px"
          width="46px"
          color="#1c2a5e"
          rounded
          depressed
          fab
          :href="link"
          target="_blank"
          class="mr-3"
        >
          <v-icon color="white" :size="size"> {{ icon }}</v-icon>
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { getContact } from "../../utils/getContact";
import { menuFooter } from "../../config";
import { getSocial } from "../../utils/getSocial";
import TitleWidget from "../widgets/TitleWidget.vue";

export default {
  data: () => ({
    year: new Date().getFullYear(),
    menuFooter,
  }),
  computed: {
    informations() {
      return this.$rest("informations").item;
    },
    contacts() {
      return getContact(this.informations);
    },
    social() {
      return getSocial(this.informations);
    },
  },
  components: {
    TitleWidget,
  },
};
</script>

<style lang="scss" scoped>
.white-space {
  white-space: break-spaces !important;
}
</style>
