var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"contact-section"},[_c('div',{staticClass:"d-flex justify-start"},[_c('TitleWidget',[_vm._v("Informações de contato")])],1),_c('div',{staticClass:"d-flex flex-column mt-8 align-start"},[_c('v-row',{attrs:{"no-gutters":"","justify":"start","align":"start"}},_vm._l((_vm.contacts),function(item,keyContact){return _c('v-col',{key:keyContact,staticClass:"d-flex justify-start pa-0",attrs:{"cols":item.xs,"sm":item.sm}},[(!Array.isArray(item))?_c('a',{staticClass:"\n            white-space\n            d-flex\n            align-center\n            text-none\n            mb-3\n            py-1\n            text-decoration-none\n            px-0\n          ",class:item.type === "highlight"
              ? "text-md-subtitle-1 text-body-1 font-weight-bold highlight mr-5"
              : "text-body-2 font-weight-medium",style:(item.type == 'highlight' ? 'color: #009fe4' : 'color: #2c2c2c'),attrs:{"target":"_blank","href":item.link,"depressed":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":item.type === "highlight" ? "#009fe4" : "#2c2c2c","size":item.size}},[_vm._v(" "+_vm._s(item.icon)+" ")]),_c('div',[(typeof item.text === "object")?_c('span',{staticClass:"text-caption font-weight-bold"},[_vm._v(" "+_vm._s(item.text.ddd)+" ")]):_vm._e(),_c('span',{staticClass:"break-word"},[_vm._v(" "+_vm._s(typeof item.text === "object" ? item.text.tel : item.text))])])],1):_vm._l((item),function(ref,keyContactItem){
              var name = ref.name;
              var text = ref.text;
              var link = ref.link;
              var target = ref.target;
              var icon = ref.icon;
              var size = ref.size;
              var type = ref.type;
return _c('v-col',{key:keyContactItem,staticClass:"d-flex align-start justify-start pa-0",attrs:{"cols":"6"}},[_c('a',{staticClass:"\n              white-space\n              rounded-0\n              d-flex\n              align-center\n              mb-3\n              px-0\n              py-1\n              text-decoration-none\n            ",class:type
                ? "text-sm-subtitle-1 text-h6 primary--text highlight text--lighten-2 font-weight-bold mr-5"
                : "text-caption",attrs:{"depressed":"","height":"auto","min-height":"36px","href":link,"target":target,"title":name,"color":"transparent"}},[(icon)?_c('v-icon',{staticClass:"mr-2",attrs:{"color":type ? "primary lighten-2" : "","size":size}},[_vm._v(" "+_vm._s(icon)+" ")]):_vm._e(),_c('span',{domProps:{"innerHTML":_vm._s(text)}})],1)])})],2)}),1),_c('div',[_c('div',{staticClass:"font-weight-bold text-subtitle-1 mb-3 mt-5"},[_vm._v(" Siga-nos nas redes sociais ")]),_vm._l((_vm.social),function(ref,i){
                var link = ref.link;
                var size = ref.size;
                var icon = ref.icon;
return _c('v-btn',{key:i,staticClass:"mr-3",attrs:{"height":"46px","width":"46px","color":"#1c2a5e","rounded":"","depressed":"","fab":"","href":link,"target":"_blank"}},[_c('v-icon',{attrs:{"color":"white","size":size}},[_vm._v(" "+_vm._s(icon))])],1)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }