import { getTelLinkFromPhone } from "./getTelLinkFromPhone";
import { getWhatsappLinkFromPhone } from "./getWhatsappLinkFromPhone";

export function getContact(component) {
  const { tel, whatsapp, email, address, google_maps, opening_hours } =
    component;
  const contact = {};

  if (tel) {
    let telSplit = tel.split(";");

    if (Array.isArray(telSplit) && telSplit.length > 1) {
      let telArray = [];
      telSplit.forEach(function (val) {
        const [ddd, numbers] = val.split(/ (.+)/, 2);
        telArray.push({
          name: "Telefone",
          text: { ddd: ddd, tel: numbers },
          link: getTelLinkFromPhone(val),
          target: "_self",
          icon: "fa-phone-alt",
          size: 24,
          type: "highligt",
          xs: "12",
          sm: "6",
        });
      });

      contact.tel = telArray;
    } else {
      const [ddd, numbers] = tel.split(/ (.+)/, 2);
      contact.tel = {
        name: "Telefone",
        text: { ddd: ddd, tel: numbers },
        link: getTelLinkFromPhone(tel),
        target: "_self",
        icon: "fa-phone-alt",
        size: 24,
        type: "highlight",
        xs: "12",
        sm: "6",
      };
    }
  }

  if (whatsapp) {
    let whatsappSplit = whatsapp.split(";");

    if (Array.isArray(whatsappSplit) && whatsappSplit.length > 1) {
      let whatsappArray = [];
      whatsappSplit.forEach(function (val) {
        const [ddd, numbers] = val.split(/ (.+)/, 2);

        whatsappArray.push({
          name: "WhatsApp",
          text: { ddd: ddd, tel: numbers },
          link: getWhatsappLinkFromPhone(val),
          target: "_blank",
          icon: "fab fa-whatsapp",
          size: 24,
          type: "highlight",
          xs: "12",
          sm: "6",
        });
      });

      contact.whatsapp = whatsappArray;
    } else {
      const [ddd, numbers] = whatsapp.split(/ (.+)/, 2);
      contact.whatsapp = {
        name: "WhatsApp",
        text: { ddd: ddd, tel: numbers },
        link: getWhatsappLinkFromPhone(whatsapp),
        target: "_blank",
        icon: "fab fa-whatsapp",
        size: 24,
        type: "highlight",
        xs: "12",
        sm: "6",
      };
    }
  }

  if (opening_hours) {
    contact.opening_hours = {
      name: "Horário de atendimento",
      text: opening_hours,
      icon: "far fa-clock",
      size: 18,
      xs: "12",
      sm: "12",
    };
  }

  if (address) {
    contact.address = {
      name: "Endereço",
      text: address,
      link: google_maps,
      target: "_blank",
      icon: "fa-map-marker-alt",
      size: 18,
      xs: "12",
      sm: "12",
    };
  }

  if (email) {
    contact.email = {
      name: "E-mail",
      text: email,
      link: "mailto:" + email,
      target: "_self",
      icon: "fa-envelope",
      size: 18,
      xs: "12",
      sm: "12",
    };
  }

  return contact;
}
