<template>
  <div class="justify-center justify-lg-start px-md-16 mt-6 items">
    <template v-for="({ icon, title, text }, keyItems) in items">
      <div :key="keyItems" class="item">
        <div class="position-relative">
          <v-sheet
            class="pa-4 pa-sm-8 mx-auto rounded-circle elevation-6"
            color="primary"
            width="50%"
          >
            <img
              class="d-block item-img"
              :src="icon"
              :alt="title"
              width="100%"
            />
          </v-sheet>

          <div class="pt-4 pb-7 px-4 item-content">
            <div
              class="
                white--text
                text-caption text-sm-body-1 text-md-h6 text-center
                font-weight-medium
              "
            >
              {{ title }}
            </div>
            <div
              class="
                white--text
                text-caption text-sm-body-1 text-md-h6 text-center
                font-weight-light
                mt-4
              "
            >
              {{ text }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
const _data = {
  items: [
    {
      icon: require("../assets/img/gabinete.png"),
      title: "Gabinete em aço inox AISI 304",
      text: "Garantia de durabilidade e resistência à corrosão.",
    },
    {
      icon: require("../assets/img/controle.png"),
      title: "Sistema de controle remoto",
      text: "Permite a operação à distância para maior conveniência e segurança.",
    },
    {
      icon: require("../assets/img/valvula.png"),
      title: "Válvulas especiais em PVC",
      text: "Resistência à corrosão, alta durabilidade e excelente desempenho.",
    },
    {
      icon: require("../assets/img/bomba.png"),
      title: "Bomba de diafragma em viton",
      text: "Desempenho de bombeamento superior e confiável.",
    },
  ],
};

export default {
  data: () => ({
    ..._data,
  }),
};
</script>

<style lang="scss" scoped>
.items {
  display: grid;
  gap: 30px;
  grid-template-columns: repeat(2, 280px);

  @media screen and (max-width: 599.9px) {
    gap: 12px;
    grid-template-columns: 50% 50%;
  }

  .item {
    position: relative;

    &:before {
      border: 2px solid var(--v-primary-base);
      border-radius: 20px;
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: none;
      position: absolute;
      right: 0;
      top: 70px;
    }

    &-img {
      aspect-ratio: 1;
      object-fit: contain;
      pointer-events: none;
    }
  }
}
</style>
