<template>
  <div class="header">
    <v-container
      class="pt-10 pt-md-13 pt-lg-16 px-3 px-sm-10 px-md-13 px-lg-16"
    >
      <div class="grid-list">
        <div>
          <div
            class="white--text font-weight-light item-title"
            v-html="title"
          ></div>

          <v-img class="trator" src="../assets/img/trator.png" contain></v-img>
        </div>

        <div>
          <v-img
            class="carreta"
            src="../assets/img/carreta.png"
            width="100%"
            contain
          ></v-img>
          <v-img
            class="logo"
            src="../assets/img/logo-bruta-pro-limpeza.png"
            contain
          ></v-img>
        </div>
      </div>
    </v-container>
  </div>
</template>

<script>
const _data = {
  title:
    'Seu Caminhão ou máquina agrícola merece o <span class="secondary--text font-weight-black big-text">melhor</span> <span class="font-weight-black big-text">cuidado!</span>',
};
export default {
  data: () => ({
    ..._data,
  }),
};
</script>

<style lang="scss" scoped>
.header {
  background-image: url(../assets/img/bg-topo.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;

  .grid-list {
    display: grid;
    grid-template-columns: 50% 50%;

    .item-title {
      font-size: 66px;
      letter-spacing: -4px !important;
      line-height: 1 !important;
      position: relative;
      z-index: 99;

      @media screen and (max-width: 1200px) {
        font-size: 56px;
      }

      @media screen and (max-width: 1000px) {
        font-size: 46px;
      }

      @media screen and (max-width: 800px) {
        font-size: 36px;
      }

      @media screen and (max-width: 620px) {
        font-size: 30px;
        letter-spacing: -1px !important;
      }

      @media screen and (max-width: 550px) {
        font-size: 26px;
      }

      @media screen and (max-width: 460px) {
        font-size: 22px;
      }
    }
  }
}

.item-title::v-deep {
  .big-text {
    font-size: 180% !important;
    line-height: 0.6 !important;
  }

  .big-text:first-child {
    display: inline-block;
    margin-top: 10px;
  }

  .big-text:last-child {
    display: inline-block;
    margin-left: 10px;
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.4);
  }
}

.carreta {
  margin-top: 50px;
  transform: scale(1.5);

  @media screen and (max-width: 1350px) {
    margin-left: 100px;
  }

  @media screen and (max-width: 800px) {
    margin-left: 30px;
  }

  @media screen and (max-width: 460px) {
    margin-top: 10px;
  }
}

.trator {
  margin-bottom: -50px;
  margin-left: -15%;
  margin-top: 120px;
  transform: scale(1.5);

  @media screen and (max-width: 620px) {
    margin-top: 60px;
  }

  @media screen and (max-width: 550px) {
    margin-top: 60px;
  }

  @media screen and (max-width: 460px) {
    margin-top: 40px;
  }
}

.logo {
  width: 80%;

  @media screen and (max-width: 1200px) {
    width: 100%;
  }
}
</style>
