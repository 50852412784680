const server = process.env.VUE_APP_SERVER;

const menu = [
  {
    text: "Início",
    to: "/",
  },
  {
    text: "Quem somos",
    to: "/quem-somos",
  },
  {
    text: "Produtos",
    to: "/produtos",
  },
  {
    text: "Parceiros",
    to: "/parceiros",
  },
  {
    text: "Distribuidores",
    to: "/distribuidores",
  },
  {
    text: "Fale Conosco",
    to: "/fale-conosco",
  },
];

const menuFooter = menu.slice(1, 6);
menuFooter.push({
  text: "Política de Privacidade",
  to: "/politica-de-privacidade",
});

export { server, menu, menuFooter };
