<template>
  <div class="footer-shell">
    <div class="footer">
      <v-container
        class="py-10 py-md-13 py-lg-16 px-3 px-sm-10 px-md-13 px-lg-16"
      >
        <div class="grid-list">
          <div>
            <div
              class="
                white--text
                text-body-1 text-sm-h5 text-md-h4
                font-weight-light
                ck-content
              "
              v-html="title"
            ></div>

            <div
              class="
                d-inline-block
                primary
                white--text
                text-body-1 text-sm-h5 text-md-h4 text-center
                font-weight-medium
                rounded-pill
                py-2
                px-10
                mt-6
              "
              v-html="subtitle"
            ></div>

            <template v-if="!lgAndUp">
              <v-img
                v-if="img && img.src"
                class="mt-6 machine"
                v-bind="img"
                width="100%"
                contain
              />

              <div
                class="
                  white--text
                  text-body-1 text-sm-h5 text-md-h4 text-center
                  font-weight-light
                  px-10 px-md-13 px-lg-16
                  ck-content
                "
                v-html="caption"
              ></div>
            </template>

            <List />
          </div>

          <div v-if="lgAndUp">
            <v-img
              v-if="img && img.src"
              class="machine"
              v-bind="img"
              width="100%"
              contain
            />

            <div
              class="
                white--text
                text-body-1 text-sm-h5 text-md-h4 text-center
                font-weight-light
                px-10 px-md-13 px-lg-16
                ck-content
              "
              v-html="caption"
            ></div>
          </div>
        </div>

        <div
          class="
            white--text
            text-caption text-sm-body-2 text-center
            font-weight-light
            mt-8
          "
          v-html="text"
        ></div>
      </v-container>
    </div>

    <v-sheet
      class="map-contact d-flex align-center"
      color="#f7f7f7"
      min-height="500px"
      style="position: relative"
    >
      <div v-if="!smAndDown" class="iframe-content">
        <div class="iframe-content-container" v-html="informations.map"></div>
      </div>
      <v-container>
        <v-row justify="center" justify-md="end">
          <v-col class="pt-12 pt-sm-8 pt-lg-5" cols="12" md="5">
            <ContactSection />
          </v-col>
          <v-col class="pa-0 mt-10 mt-sm-0" cols="12">
            <div v-if="smAndDown" v-html="informations.map"></div>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>

    <WhatsAppButtonWidget
      v-if="informations.whatsapp_float"
      :link="informations.whatsapp_float"
    />
  </div>
</template>

<script>
import List from "./List.vue";
import ContactSection from "../components/sections/ContactSection.vue";
import WhatsAppButtonWidget from "../components/widgets/WhatsAppButtonWidget.vue";
import { menuFooter } from "../config";
import { formatToURL } from "../utils/formatToURL";

const _data = {
  title:
    'Apresentamos a <b class="font-weight-black">Bruta Pró-Limpeza</b> da Sinodet, a solução definitiva para uma limpeza industrial completa e eficiente.',
  subtitle: "Alta eficiência, baixo custo operacional!",
  img: {
    src: require("../assets/img/bruta-pro-limpeza.png"),
    alt: "Bruta Pró-limpeza",
  },
  caption: '<b class="font-weight-black">Economize</b> tempo, água e dinheiro!',
  text: '<span class="font-weight-medium">Características técnicas:</span> Modelo: W01 | Tensão de trabalho: 220V | Consumo de energia: 200W/h | Consumo de ar comprimido: 2,6 mº de ar por minuto (máximo) Consumo máximo de produto por minuto: 5 litros | Dimensões do equipamento: 500 mm x 400 mm x 220 mm | Dimensões do painel de comando: 300 mm x 200 mm x 135 mm Material do painel de comando: termoplástico | Material da caixa da bomba: aço inox ATSI 304',
};

export default {
  data: () => ({
    ..._data,
    year: new Date().getFullYear(),
    menuFooter,
  }),
  computed: {
    categories() {
      return this.$rest("categories").list.map(({ title }) => {
        return {
          to: `/produtos/${formatToURL(title)}`,
          text: title,
        };
      });
    },
    informations() {
      return this.$rest("informations").item;
    },
    smAndDown() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    lgAndUp() {
      return this.$vuetify.breakpoint.lgAndUp;
    },
  },
  created() {
    this.$rest("informations").get({
      save: (state, data) => (state.item = data),
    });

    this.$rest("categories").get();
  },
  components: {
    ContactSection,
    List,
    WhatsAppButtonWidget,
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background-image: url(../assets/img/bg-base.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .grid-list {
    display: grid;
    gap: 20px;
    grid-template-columns: auto 497px;

    @media screen and (max-width: 1279.9px) {
      grid-template-columns: minmax(0, 720px);
      justify-content: center;
    }
  }
}
.footer-shell::v-deep {
  .iframe-content {
    width: 50%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .iframe-content-container {
      height: 100%;
      width: 100%;
    }
  }

  iframe {
    width: 100% !important;
    height: 100% !important;
    min-height: 300px;
    display: block !important;
  }
}
</style>
